<template>
  <div>
    <validation-observer ref="suggestion">
      <b-form>
        <b-row>
          <b-col
            cols="12"
            md="8"
          >
            <b-card no-body>
              <b-card-header>
                <h4 class="card-title">{{isNew ? i18nT(`Suggestion`): i18nT(`Edit suggestion`)}}</h4>
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  right
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="onEdit()">
                    <span class="align-middle ml-50">{{i18nT(`Edit`)}}</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="onSave()">
                    <span class="align-middle ml-50">{{i18nT(`Save`)}}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="hasRouteId"
                    @click="onDelete()"
                  >
                    <span
                      class="align-middle ml-50"
                      style="color: red"
                    >{{i18nT(`Delete`)}}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-card-header>
              <b-card-body>
                <b-row class="border-bottom pb-2">
                  <!-- Field: Username -->
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <h5 class="mt-2">{{i18nT(`Suggestion details`)}}</h5>
                  </b-col>
                  <b-col
                    cols="12"
                    md="9"
                  >
                    <b-row>
                      <b-col cols="12">
                        <b-form-group
                          :label="i18nT(`Title`)" class="required"
                          label-for="suggestionLabel"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="i18nT(`Title`)"
                            rules="required"
                          >
                            <b-form-input
                              v-model="suggestion.suggestion.Label"
                              :placeholder="i18nT(`E.g. Suggestion Name`)"
                              name="suggestionLabel"
                              id="suggestionLabel"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group
                          :label="i18nT(`Type`)"
                          label-for="suggestionType"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="i18nT(`Type`)"
                            rules="required"
                          >
                            <v-select
                              v-model="suggestion.suggestion.Type"
                              :options="suggestionTypes_form"
                              :reduce="(e) => (e.value == '' ? '' : parseInt(e.value))"
                              label="text"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group
                          :label="i18nT(`Description`)"
                          label-for="suggestionDescription"
                          class="required"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="i18nT(`Description`)"
                            rules="required"
                          >
                              <quill-editor
                                  id="Description"
                                  v-model="suggestion.suggestion.Description"
                                  :options="quillOption"
                                  :row="10"
                                  :state="errors.length > 0 ? false : null"
                              />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group
                          label-for="suggestionAnonymous"
                        >
                          <b-form-checkbox
                            v-model="suggestion.suggestion.Anonymous"
                            value="1"
                            unchecked-value="0"
                            switch
                            inline
                            id="suggestionAnonymous"
                          >
                          {{i18nT(`Post in anonymous mode`)}}
                        </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <b-row class="border-bottom py-2">
                  <!-- Field: Username -->
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <h5 class="mt-2">{{i18nT(`Documents`)}}</h5>
                  </b-col>
                  <b-col
                    cols="12"
                    md="9"
                    class="pt-2"
                  >
                    <b-row>
                      <b-col cols="12">
                        <h5>{{i18nT(`Add documents`)}}</h5>
                        <div>
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-primary"
                            class="mt-1"
                            @click.prevent="onAddNewDocumentClick"
                          >
                            <feather-icon icon="PlusCircleIcon" />
                            {{i18nT(`Add file`)}}
                          </b-button>
                        </div>
                        <hr />
                        <h5>{{i18nT(`Selected documents`)}}</h5>
                        <draggable
                          v-if="suggestion && suggestion.documents"
                          v-model="suggestion.documents"
                          tag="ul"
                          class="list-group cursor-move"
                        >
                          <b-list-group-item
                            v-for="(document, index) in suggestion.documents"
                            :key="index"
                            tag="li"
                          >
                            <b-button-group
                              class="position-absolute"
                              style="right: 10px"
                            >
                               <feather-icon
                                  icon="Edit2Icon"
                                  size="17"
                                  class="cursor-pointer d-sm-block d-none mr-1"
                                  @click="editSuggestionDocumentByIdx(index)"
                              />
                              <feather-icon
                                  icon="Trash2Icon"
                                  size="17"
                                  class="cursor-pointer d-sm-block d-none mr-1"
                                  @click="removeSuggestionDocumentByIdx(index)"
                              />
                            </b-button-group>
                            <h5>{{ document.Label }}</h5>
                          </b-list-group-item>
                        </draggable>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <b-row>
                  <div style="flex: 1" />
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-0 mt-md-2 ml-1 mr-1"
                    @click.prevent="onUpdateSuggestionSubmit"
                  >
                  {{i18nT(`Save`)}}
                  </b-button>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col cols="12" md="4">
            <b-card no-body>
              <b-card-header>
                <h5 class="card-title">{{i18nT(`Information`)}}</h5>
              </b-card-header>
              <b-card-body>
                <b-row v-if="suggestion.suggestion.CreatedAt" class="border-bottom pb-2">
                  <b-col cols="12">
                    <ul class="bg-light text-dark p-2" style="list-style: none;">
                      <li><feather-icon icon="CalendarIcon" size="16" /> Created: <strong>{{suggestion.suggestion.CreatedAt | date}}</strong></li>
                      <li><feather-icon icon="PieChartIcon" size="16" /> Last Updated: <strong>{{suggestion.suggestion.UpdatedAt | date}}</strong></li>
                    </ul>           
                  </b-col>
                  <b-col cols="12">
                    <p>{{suggestion.votes.length}} Votes</p>
                    <p>{{suggestion.comments.length}} Comments</p>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-modal
      id="modal-document"
      :title="i18nT(`Add documents`)"
      ok-only
      :ok-title="i18nT(`Add new`)"
      @ok="onAddNewDocumentSubmit"
      @hidden="clearDocumentData"
      no-close-on-backdrop
      size="lg"
    >
      <b-tabs
        pills
        align="center"
        v-model="tabIndex"
      >
        <b-tab>
          <template #title>
            <feather-icon icon="FolderIcon" /> {{i18nT(`Existing document`)}}
          </template>
          <validation-observer ref="existingDocument">
            <b-row>
              <b-col sm="12">
                <b-form-group
                  :label="i18nT(`Select document`)" class="required"
                  label-for="existingDocument"
                >
                  <v-select
                    v-model="selectedDocument"
                    :options="existDocuments"
                    label="text"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="UploadCloudIcon" /> {{i18nT(`New document`)}}
          </template>
          <validation-observer ref="newDocument">
            <b-form>
              <b-row class="border-bottom">
                <b-col cols="12">
                  <b-row>
                    <b-col>
                      <b-form-group
                        :label="i18nT(`Title`)"
                        label-for="title"
                        class="required"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Title"
                          rules="required"
                        >
                          <b-form-input
                            id="title"
                            v-model="document.Label"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <div v-if="type !== 'Freelancer'">
                    <b-row>
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-checkbox
                          name="check-button"
                          switch
                          :checked="document.Template"
                          v-model="document.Template"
                        >
                          {{i18nT(`Save as template`)}}
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                    <b-alert
                      class="mt-1"
                      variant="primary"
                      show
                    >
                      <div class="alert-body">
                        <span>
                          <b-badge variant="primary">
                            {{i18nT(`Info`)}}
                          </b-badge>
                            {{i18nT(`You can save common documents as templates for later use. You will find them in Document templates.`)}}
                        </span>
                      </div>
                    </b-alert>
                  </div>
                  <b-row v-if="displayExtentControls">
                    <b-col cols="6">
                      <b-form-group
                        :label="i18nT(`Document type`)"
                        label-for="Type"
                        class="required"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Document Type"
                          rules="required"
                        >
                          <b-form-select
                            v-model="document.Type"
                            :options="documentTypes"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        :label="i18nT(`Put in document groups`)"
                        label-for="DocumentGroups"
                      >
                        <v-select
                          v-model="document.DocumentGroups"
                          :options="documentGroups"
                          multiple
                          label="text"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      md="12"
                      v-if="displayExtentControls"
                    >
                      <b-form-group
                        :label="i18nT(`Assign to`)"
                        label-for="AssignedTo"
                      >
                        <v-select
                          v-model="document.AssignedTo"
                          :options="assigneeFilter"
                          label="text"
                          multiple
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <b-form-checkbox
                        name="check-button"
                        switch
                        v-model="document.Share"
                      >
                        Share the document with assigned people
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-alert
                    class="mt-1"
                    variant="primary"
                    show
                  >
                    <div class="alert-body">
                      <span>
                        <b-badge variant="primary">
                          Info
                        </b-badge>
                        Shared documents with employees are visible in their accounts. You can restrict the access at any time.
                      </span>
                    </div>
                  </b-alert>
                  <b-row>
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <b-form-group :label="i18nT(`Specific to software module`)">
                        <v-select
                          v-model="Software"
                          multiple
                          label="title"
                          :options="softwares"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        :label="i18nT(`Description`)"
                        label-for="description"
                      >
                        <b-form-textarea
                          id="textarea-default"
                          rows="3"
                          v-model="document.Description"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="pt-2 pb-2">
                <b-col cols="12">
                  <b-tabs>
                    <b-tab :active="hasFile">
                      <template #title>
                        <feather-icon
                          icon="UploadCloudIcon"
                          size="18"
                          style="margin-right: 8px;"
                        />
                        <span>{{i18nT(`File`)}}</span>
                      </template>
                      <div>
                        <div>
                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            class="mr-1 mb-2"
                            @click="onFilePick"
                          >
                            <feather-icon icon="PlusCircleIcon" />
                            Add file
                          </b-button>
                          <b-form-file
                            v-model="file"
                            ref="filePicker"
                            class="hidden"
                          />
                          <span v-if="file">
                            {{fileName}}
                            <feather-icon
                              icon="Trash2Icon"
                              size="16"
                              style="margin-right: 8px;"
                              @click="onFileDelete"
                            />
                          </span>
                          <span v-if="!file">
                            {{originalFileName}}
                          </span>
                        </div>
                        <p>Attach images, text files, spreadsheets, pdf documents and videos. </p>
                      </div>
                    </b-tab>
                    <b-tab :active="hasUrl">
                      <template #title>
                        <feather-icon
                          icon="LinkIcon"
                          size="18"
                          style="margin-right: 8px;"
                        />
                        <span>{{i18nT(`URL link`)}}</span>
                      </template>
                      <div>
                        <b-input-group>
                          <template #prepend>
                            <b-input-group-text>
                              <feather-icon
                                icon="LinkIcon"
                                size="18"
                              />
                            </b-input-group-text>
                          </template>
                          <b-form-input
                            :placeholder="i18nT(`URL`)"
                            v-model="document.Url"
                          />
                        </b-input-group>
                        <p class="pt-2">Enter a link to images, text files, spreadsheets, pdf documents and videos. </p>
                      </div>
                    </b-tab>
                  </b-tabs>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BAlert,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BCard,
  BFormCheckbox,
  BFormTextarea,
  BFormFile,
  BFormSelect,
  BInputGroup,
  BInputGroupText,
  BCardBody,
  BListGroupItem,
  BCardHeader,
  BDropdown,
  BDropdownItem,
  BTabs,
  BTab
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import useAuth from '@/auth/useAuth'
import draggable from 'vuedraggable'
import {quillEditor} from 'vue-quill-editor'
import { dictToSelectArray } from '@core/utils/utils'

export default {
  components: {
    BButton,
    BButtonGroup,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BForm,
    BCard,
    vSelect,
    BFormTextarea,
    BFormSelect,
    BAlert,
    BBadge,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupText,
    BListGroupItem,
    draggable,
    quillEditor,
    BCardHeader,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BTabs,
    BTab
  },
  directives: {
    Ripple
  },
  data() {
    const displayExtentControls = useAuth.getCurrentSoftware() != 'ca'

    return {
      required,
      tabIndex: 0,
      selectedDocument: null,
      existDocuments: [],
      suggestionTypes: [],
      suggestionTypes_form: [],
      suggestionDocuments: [],
      suggestion: {
        suggestion: {
          Anonymous: 0
        },
        documents: [],
        votes: [],
        comments: []
      },
      answersData: [''],
      linesCnt: 1,
      possibleAnswers: 1,
      otherOption: false,
      steps: 0,
      stepOptions: [2, 3, 5, 10],
      stepStrings: [null, null, null, null, null, null, null, null, null, null],
      firstStep: '',
      lastStep: '',
      grading: 0,
      document: {},
      Software: [],
      documentTypes: [],
      documentGroups: [],
      assigneeFilter: [],
      file: null,
      fileName: null,
      displayExtentControls,
      type: useAuth.getUserType(),
      hasUrl: false,
      hasFile: false,
      originalFileName: null,
      Anonymous: 0,
        quillOption: {
            theme: 'snow',
            placeholder: '',
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline', 'link'],
                    [{list: 'ordered'}, {list: 'bullet'}],
                    ['clean']
                ]
            }
        }
    }
  },
  watch: {
    file: function(val) {
      if (val) {
        this.file = val
        this.fileName = val.name
      } else {
        this.file = null
        this.fileName = ''
      }
    }
  },
  async created() {
    const id = this.$route.params.id
    // eslint-disable-next-line no-undef
    this.softwares = _.values(useAuth.getSoftwares()).map(software => ({value: software.id, title: `${software.short.toUpperCase()} - ${software.title}`}))

    Promise.all([
      new Promise(res => {
        this.$http.get(`suggestions/suggestionTypes`).then(({data}) => {
          const suggestionTypes = data.data
          for (const property in suggestionTypes) {
            this.suggestionTypes.push({
              value: property,
              text: suggestionTypes[property]
            })
          }
          this.suggestionTypes_form = this.suggestionTypes.filter(
            option => option.value != '0'
          )
          res()
        })
      }),
      new Promise(res => {
        this.$http.get('documents?show_per_page=1000').then(({ data }) => {
          this.existDocuments = data.data.documents.map(d => ({
            value: d.Id,
            text: d.Label,
            ...d
          }))
        })
        res()
      }),
      new Promise(res => {
        this.$http.get(`documents/documentTypes`).then(({ data }) => {
          const types = data.data.map(type => ({
            value: type.Id,
            text: type.Label
          }))

          this.documentTypes = types
          res()
        })
      }),
      new Promise(res => {
        this.$http.get(`documents/documentGroups`).then(({ data }) => {
          const documentGroups = data.data.document_groups.map(type => ({
            value: type.Id,
            text: type.Label
          }))

          this.documentGroups = documentGroups
          res()
        })
      }),
      this.type != 'Freelancer' &&
        new Promise(res => {
          this.$http.get(`documents/assignToUsersList`).then(({ data }) => {
            this.assigneeFilter = dictToSelectArray(data.data.users_list)
            res()
          })
        })
    ]).then(() => {
      if (id !== 'new') {
        this.$http.get(`suggestions?id=${id}`).then(({ data }) => {
          const suggestion = data.data
          this.suggestion = suggestion
          
          this.Software = []
          if (this.document.Soft1 == 1) {
            this.Software.push(
              this.softwares.filter(software => software.value === 1)[0]
            )
          }
          if (this.document.Soft2 == 1) {
            this.Software.push(
              this.softwares.filter(software => software.value === 2)[0]
            )
          }
          if (this.type != 'Freelancer') {
            if (this.document.Soft3 == 1) {
              this.Software.push(
                this.softwares.filter(software => software.value === 3)[0]
              )
            }
            if (this.document.Soft4 == 1) {
              this.Software.push(
                this.softwares.filter(software => software.value === 4)[0]
              )
            }
          }
        })
      }
    })
  },
  computed: {
    hasRouteId() {
      return router && router.currentRoute.params.id
    },
    isNew() {
      return router && router.currentRoute.params.id === 'new'
    }
  },
  methods: {
    clearDocumentData() {
      console.log('tabIndex: ', this.tabIndex)
    },
    onAddNewDocumentSubmit(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()

      if (this.tabIndex === 0) {
        this.$refs.existingDocument
          .validate()
          .then((success) => {
            if (success) {
              this.suggestion.documents.push(this.selectedDocument);
              this.selectedDocument = null;
              this.$bvModal.hide("modal-document");
            }
          })
          .catch((error) => {
            console.log(error);
          })
      } else {
        this.$refs.newDocument
          .validate()
          .then((success) => {
            if (success) {
              const formData = new FormData()
              if (this.document.Id !== 'new') formData.append('Id', this.document.Id)
              formData.append('Label', this.document.Label)
              formData.append('Type', this.document.Type)
              formData.append('Description', this.document.Description)
              if(this.document.AssignedTo) {
                formData.append('assigned_to', this.document.AssignedTo.map(assignee => assignee.value).join(","))
              }
              if(this.document.DocumentGroups) {
                formData.append('document_groups', this.document.DocumentGroups.map(group => group.value).join(","))
              }
              formData.append('assigned_softs', this.Software.map(document => document.value).join(","))
              if(this.file) {
                formData.append('fileUp', this.file)
              }

              if(this.type !== 'Freelancer') {
                formData.append('ShareWithFreelancer', this.document.Share)
                formData.append('IsTemplate', this.document.Template ? 1: 0)
              }
              else {
                formData.append('ShareWithClient', this.document.Share)
              }

              const selectedSofware = useAuth.getCurrentSoftware();
              formData.append('Soft1', selectedSofware === 're' ? 1: 0)
              formData.append('Soft2', selectedSofware === 'on' ? 1: 0)
              formData.append('Soft3', selectedSofware === 'hr' ? 1: 0)
              formData.append('Soft4', selectedSofware === 'en' ? 1: 0)

              formData.append('Url', this.document.Url)
              this.$http.post(
                'documents', formData
              ).then((res) => {
                this.suggestion.documents.push(res.data.data);
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: this.successMessage(res),
                    icon: 'InfoIcon',
                    variant: 'success',
                  },
                })
                const { data } = res
                this.$bvModal.hide("modal-document")
                if (this.document.Id === 'new') this.suggestion.documents.push(data.data)
                this.document = {}
              })
              .catch((err) => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: this.errorMessage(err),
                    icon: 'InfoIcon',
                    variant: 'danger',
                  },
                })
              })
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    onUpdateSuggestionSubmit() {
      // Prevent modal from closing
      const id = this.$route.params.id

      this.$refs.suggestion
        .validate()
        .then(success => {
          if (!success) return
          // eslint-disable-next-line

          const formData = new FormData()
          if (id !== 'new') formData.append('id', id)

          formData.append('Label', this.suggestion.suggestion.Label)
          formData.append('Type', parseInt(this.suggestion.suggestion.Type))
          formData.append('Description', this.suggestion.suggestion.Description)
          formData.append('Anonymous', parseInt(this.suggestion.suggestion.Anonymous))
          formData.append('suggestion_documents', this.suggestion.documents.map((q) => q.Id).join())

          this.$http
            .post('suggestions', formData)
            .then(res => {              
              router.push({ name: "suggestions"});
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.successMessage(res),
                  icon: 'InfoIcon',
                  variant: 'success'
                }
              })
            })
            .catch(err => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.errorMessage(err),
                  icon: 'InfoIcon',
                  variant: 'danger'
                }
              })
            })
        })
        .catch(error => {
          console.log(error)
        })
    },
    onAddNewDocumentClick() {
      this.$bvModal.show('modal-document')
    },
    editSuggestionDocumentByIdx(index) {
      this.document = this.suggestion.documents[index];
      console.log(this.document)
      this.tabIndex = 1
      this.$bvModal.show('modal-document')
    },
    removeSuggestionDocumentByIdx(index) {
      this.suggestion.documents.splice(index, 1);
    },
    onFilePick: function() {
      this.$refs['filePicker'].$refs.input.click()
    },
    onFileDelete: function() {
      this.file = null
      this.fileName = ''
    },
    onSave() {
      console.log('Saved!!!')
    },
    onDelete() {
      this.$swal({
        title: 'Are you sure you want to delete this entry?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          console.log('Deleted!!!')
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
.section-title {
  font-size: 11px;
}
.document-name {
  display: inline;
  line-height: 32px;
}
.document-actions-bar {
  float: right;
}
</style>
